@import './scss/abstracts/variables.module';
@import './scss/abstracts/mixins';

$textPlaceHolderColor: #8c8c8c;
$tableBorder: #d6d6d6;
$mid-blue: color(midBlue);
$black: color(black);
$subHeaderColor: color(grey);
@mixin remove-number-input-spinners {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

@mixin add-left-border {
  &::after {
    content: '';
    position: absolute;
    top: -6px;
    right: 0px;
    height: 140%; /* 110% to cover the border of the parent element */
    width: 1px;
    background-color: color(lavendeGray);
  }
}

.sideBarCell {
  @extend .baseFont;
  border-collapse: collapse;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1.25em;
  min-width: 350px;
  position: relative;

  @include add-left-border;

  .actions {
    opacity: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    // gap: 0.75em;
    img,
    button {
      object-fit: contain;
      object-position: center;
      position: relative;
      margin: 0;
      height: 1em;
      width: 1em;
    }
  }

  /**
   When hovering over a table subrow, three dots and copy icons appear as a hover effect. 
   However, when a submenu is opened, we need to avoid removing this hover effect. 
   By adding a class named 'unHoverable' to the element when it is hovered, we prevent the hover effect. 
   This ensures that the icons remain visible, i.e., they do not disappear while the menu is open.
   */
  .unHoverable {
    opacity: 1;
  }

  .departmentName {
    @extend .baseFont;
    font-weight: 400;
    border-top-width: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    // habmburger icon
    img {
      margin-right: 10px;
    }
  }

  .subheader {
    position: relative;
    display: flex;
    // gap: 0.5em;
    align-items: center;
    justify-content: flex-start;
    margin: 0;

    .toggleSubrowsButton {
      img {
        transform: rotate(270deg);
        transition: 100ms;
        height: 0.5em;
        width: 0.5em;
      }
      &.expanded {
        img {
          transform: rotate(0deg);
        }
      }
    }
  }

  button {
    background: transparent;
    border-radius: 2px;
    @apply text-tablePlusButtonText;
    border: 0;
    outline: none;
    font-weight: 500;
    height: 1em;
    width: 1em;
    font-size: 1.5em;
    padding: 0;
    text-align: center;
    line-height: 0;
    opacity: 0.7;
  }

  .purple {
    @apply text-tablePurple;
  }

  .bold {
    font-weight: 600;
  }
}

.sideBarCellChild {
  @extend .sideBarCell;
  position: relative;
  transition: all 100ms ease-in-out;
  &:hover {
    background-color: color(lightBlue4);

    .actions {
      opacity: 1;
    }
  }

  .hoverable {
    svg {
      color: color(midBlue);
    }
  }
  .hoverable:hover {
    cursor: pointer;
  }
}

.tableWrapper {
  overflow-x: auto;
  overflow-y: hidden;
  @include hideScrollbar;
}

.tableWrapperContainer {
  position: relative;
  padding: 0 24px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background-color: color(white);
  }
}

#customTable {
  * {
    font-family: 'Figtree';
    outline: none;
  }

  border-collapse: collapse;
  width: 100%;
  background-color: color(white);
  border-width: 1px;
  border-color: color(lavendeGray);

  th {
    text-align: left;
    // padding: 0 24px;
    font-weight: 500;
  }

  input {
    @include remove-number-input-spinners;

    border: 0;
    outline: 0;
    height: 100%;
    display: flex;
    position: relative;

    z-index: 1;
    &:hover {
      @apply bg-tableSidebarHoverBg;
      @apply border-tableInputFocus;
    }
    &:focus {
      border: 1px solid;
      @apply border-tableInputFocus;
    }
  }

  td {
    @extend .cellBorder;
    text-align: left;
    padding: 0;
    max-height: 40px;
    max-width: 350px;
  }

  tr {
    font-weight: 500;
    padding: 0;
  }

  .stickyColumn {
    @extend .cellBorder;
    @extend .baseFont;
    position: sticky !important;
    left: -1px;
    z-index: 100;
    background-color: white;
    border-right-width: 1px !important;
    min-width: 350px;
    max-width: 350px;
    width: 350px;
  }

  .topLeftCell {
    @extend .stickyColumn;
    font-size: 22px !important;
    padding: 0 24px !important;
    border-right-width: 1px !important;
    z-index: 2;
    height: 56px;
    max-height: 56px;
    width: 350px;
    max-width: 350px;
  }

  .departmentHeaderCell {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -1px;
      height: 100%;
      width: 1px;
      background-color: color(lavendeGray);
    }
  }

  .tableHeaderContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

/* Misc. */
.empty {
  border: 1px solid color(lavendeGray);
  height: 24px;
  z-index: 20;
  border-top-width: 0px !important;
}

.gapRow {
  position: relative;
  border-width: 1px;
  z-index: 2;
  border-bottom: none;
  border-bottom-width: 0px !important;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    height: 110%;
    width: 1px;
    background-color: color(lavendeGray);
  }
}

/* Formula */

.formulaModal {
  padding: 1em;
  max-width: 750px;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  display: flex;
  height: auto;
  flex-direction: column;
  min-width: 700px;
  z-index: 1000000000000000000000;
  background: white;
  transition: 300ms;
  border-radius: 0.15em;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1em;
    h3 {
      font-size: 1.25em;
      font-weight: 600;
    }
    button {
      @apply bg-tableInputBg;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .elements {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    border: 1px solid;
    @apply border-tableBorder;
    padding: 1em;
    border-radius: 0.15em;
    input,
    .editableDiv {
      height: auto !important;
      width: 100%;
      display: flex;
      gap: 0.5em;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      text-wrap: wrap;
    }
  }

  .formulaElement {
    border: 1px solid;
    @apply border-tableBorder;
    padding: 0 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    ul {
      position: absolute !important;
      right: 0;
      top: 15px;
      background: white;
      z-index: 1;
      width: 100%;
      min-width: 100px;
    }
  }

  .inputDropdown {
    position: absolute !important;
    left: 0;
    width: 100%;
    margin: 0.5em;
    background: white;
  }
}

/* Header */

.tableHeaderOther {
  font-family: 'IBM Sans', sans-serif;

  &.first {
    font-size: 22px;

    &.w-64 {
      width: 64px;
    }

    &.text-xl {
      font-size: 1.25rem; /* Assuming a standard font-size value for XL */
    }

    &:not(:first-child) {
      &.w-24 {
        width: 24px;
      }
    }
  }
}

.tableHead {
  @extend .cellBorder;
  position: sticky;
}

.topHeader {
  background-color: color(white);
  position: relative;
  width: 100%;

  &::after {
    content: '';

    width: 100%;
    height: 100%;

    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.2;
    background: repeating-linear-gradient(
      -45deg,
      color(midBlue),
      color(midBlue) 2.5px,
      color(white) 2.5px,
      color(white) 16px
    );
  }
}

.topHeaderSearchColumn {
  display: flex;
  border: 1px solid color(lavendeGray);
}

.stickyHeader {
  @extend .baseFont;
  position: sticky;
  top: 0;
  font-size: 16px !important;
  z-index: 1; /* Make sure the header is above other elements */
  border: 1px solid color(lavendeGray);
  min-width: 115px;
  height: 56px;
  max-height: 56px;
  max-width: 160px;
}

.lastHeader {
  width: 100%;
}

.tableSettingsHeader {
  left: 0;
  background-color: white;
  z-index: 998;
  display: flex;
  position: sticky;
  max-height: 68px;
  align-items: center;
  width: 200%;
  position: sticky;
  border-left-width: 0px;
  padding: 0 !important;
  img {
    margin: 0;
    margin-left: 10px;
    margin-right: 1.5em;
    width: 19px;
    height: 19px;
  }
  th {
    border: 0px solid;
    height: 48px;
    width: 200%;
    padding: 0 !important;
    margin: 0 !important;
  }
}
.tableSettingsHeaderNavigationButtons {
  z-index: 100;
  position: absolute;
  right: 24px;
  top: 6px;
  display: none;
  justify-content: flex-end;

  & > button {
    padding: 6px;
    &:disabled {
      cursor: auto;
      & img {
        filter: invert(99%) sepia(3%) saturate(1665%) hue-rotate(242deg)
          brightness(150%) contrast(75%);
      }
    }
  }

  & img {
    margin: 0;
  }
}

.leftArrow {
  rotate: 180deg;
}

.tableSettingHeaderCell {
  @extend .baseFont;
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  align-items: center;
  display: flex;
  padding: 1 !important;
  margin: 0 !important;
  position: relative;
  z-index: 500;
  border: 1px solid color(lavendeGray);
  border-right: 1px solid !important;
  border-right-color: rgb(214 214 214 / var(--tw-border-opacity)) !important;
}

.tableSettingsHeader_search {
  @extend .tableSettingHeaderCell;
  z-index: 1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
}

.cellBorder {
  border: 1px solid;
  border-color: color(lavendeGray);
}

.searchInput {
  @extend .baseFont;
  height: 48px;
  margin: 1;
  padding: 1;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
  &::placeholder {
    /* Change the color of input placeholder */
    color: $textPlaceHolderColor; /* Set your desired color */
  }
  &:hover,
  &:focus {
    border: none !important;
    background-color: none !important;
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none;
  }
}

.baseFont {
  @apply text-tableFontBlack;
  font-family: 'Figtree';
}

.baseCell {
  @extend .baseFont;
  font-weight: 400;
  height: 100%;
}

.disabledTable {
  position: relative;
  z-index: 200 !important; // Changed to lower z-index to prevent overlapping the header
  background-color: white;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: color(disabledModeColor);
    z-index: 1;
  }
}

.modelNameInput {
  border: none !important;
  background-color: transparent !important;
  width: 100%;
  outline: none;
  padding-bottom: 3px;

  &:focus {
    border-bottom: 1.5px solid color(midBlue) !important;
    outline: none !important;
  }
}

.outputCellContainer {
  padding: 5px 10px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  gap: 0.5em;
  width: 100%;
  @include textStyle(11px, 400);

  .calculationsLabel {
    @include textStyle(11px, 400);
    color: color(black2024);
  }

  .actions {
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    .actions {
      opacity: 1;
    }
    @apply bg-tableSidebarHoverBg;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    height: 100%;
    width: 1px;
    background-color: color(lavendeGray);
  }
}

.addBtn {
  width: 230px !important;
  height: 100%;
  @include textStyle(14px, 400);
  color: color(midBlue) !important;
  display: flex !important;
  align-items: center !important;

  svg {
    margin-right: 0.5em;
  }
}

.formulaInputOptions {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 24px !important;
  cursor: pointer;

  span {
    @include textStyle(16px, 500);
  }

  &:hover {
    background: color(blue73);
  }
}

.typeOptionsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  // max-height: 59px;
  padding: 12px 24px !important;
  @include textStyle(16px, 500);
  cursor: pointer;
  gap: 12px;

  .typeOption {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 8px;
    max-height: 30px;
    max-width: 32px;

    &:hover {
      border: 1px solid color(midBlue);
    }
  }

  .activeTypeOption {
    border: 1px solid color(midBlue) !important;
  }
}

.subOptionsMenuItemContainer {
  .optionLabel {
    width: 80%;
    @include textStyle(16px, 500);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.sideBarBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: color(midBlueOpac);
  color: color(white);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 12px;
  color: color(black);
  @include textStyle(12px, 500);
}

:export {
  midBlue: $mid-blue;
  black: $black;
  subHeaderColor: $subHeaderColor;
}

// Drag and Drop

.draggingRow {
  height: auto !important;
}

@keyframes blinkBorder {
  0%,
  100% {
    border-color: transparent;
  }
  50% {
    border-color: color(midBlue);
  }
}

.showFormulaRowLocation {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 99.5%;

    border: 2px solid transparent;
    background-color: transparent;
    z-index: 1;
    animation: blinkBorder 1s ease-in-out infinite;
    animation-iteration-count: 6;
  }
}

.errorSign {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10px;
  color: color(darkOrange);
}
