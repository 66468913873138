@import '../../../styles/scss/abstracts/variables.module';

.onboardingPopupBody {
  width: 100%;
  height: 610px;
  border-radius: 16px;
  background-color: color(culturedGray);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  .onboardingPopupBodyContent {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .onboardingPopupBodyFooter {
    position: absolute;
    z-index: 100;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 16px 24px 16px 24px;
    // background: linear-gradient(
    //   180deg,
    //   rgba(250, 250, 252, 0) 0%,
    //   #fafafc 54.53%
    // );
    justify-content: flex-end;

    .backToPreviousStepButton {
      color: color(black);
      padding: 14px 16px 14px 16px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      z-index: 102;
      justify-content: center;
      @include textStyle(16, 500);
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      &:hover {
        background-color: color(lightestGrey);
      }

      &:active {
        background-color: color(lightestGrey);
      }
    }

    button {
      background-color: color(darkOrange);
      color: color(black);
      padding: 14px 16px 14px 16px;
      border-radius: 8px;
      @include textStyle(16px, 500);
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:hover {
        background-color: darken(color(darkOrange), 10%);
      }
    }

    .continue {
      background-color: color(midBlue);
      color: color(white);

      &:hover {
        background-color: darken(color(midBlue), 10%);
      }
    }

    .fastPlanButton {
      background-color: color(midBlue);
      color: color(white);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: darken(color(midBlue), 10%);
      }
    }
  }
}

.questionBoxHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  cursor: pointer;

  span {
    @include textStyle(14px, 700);
  }
}

.errorState {
  color: color(red) !important;
}

.redCircle {
  color: color(red);
}

.greenCircle {
  color: color(shamrockGreen);
}

.detailsConfirmationStage {
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .inputFieldContainer {
    width: 100%;
    margin: 10px 0;
  }
}

.calendarField {
  background-color: white;
  padding: 0 15px;
  border-radius: 6px;
  @include textStyle(14px, 400);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 45px;
  max-height: 45px;
  cursor: pointer;
  border: 1px solid hsl(0, 0%, 80%);
  color: color(black);

  svg {
    cursor: pointer;

    &:hover {
      color: color(midBlue);
    }
  }
}
