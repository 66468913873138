@import '../../../styles/scss/abstracts/variables.module.scss';

.uploadContainer {
  width: 100%;
  max-width: 600px;
  padding: 1rem;
}

.submitButtonContainer {
  z-index: 101;
  position: relative;
  span {
    display: block;
    @include textStyle(16px, 400);
    color: lighten(color(black), 30%);
    margin-top: 10px;
    text-align: center;
  }
}

.dropzone {
  border: 2px dashed color(lightGrey7);
  border-radius: 8px;
  padding: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: color(white);

  &:hover {
    border-color: color(midBlue);
    background-color: rgba(color(midBlue), 0.05);
  }
}

.dragActive {
  border-color: color(midBlue);
  background-color: rgba(color(midBlue), 0.1);
}

.dropzoneContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.uploadIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  padding: 12px;
}

.dropzoneText {
  text-align: center;
  color: color(black);
  margin: 0;
}

.fileName {
  color: color(black);
  @include textStyle(16px, 500);
  margin: 0;
}

.urlInputContainer {
  display: none;
  margin-top: 1.5rem;
}

.urlLabel {
  display: block;
  color: color(black);
  @include textStyle(16px, 500);
}

.urlInput {
  width: 100%;
  padding: 22px 12px;
  margin-top: 0.5rem;
  border: 1px solid color(lightGrey7);
  border-radius: 8px;
  @include textStyle(16px, 500);

  &:focus {
    outline: none;
    border-color: color(midBlue);
    box-shadow: 0 0 0 2px rgba(color(midBlue), 0.1);
  }
}

.submitButton {
  width: 100%;
  margin-top: 1.5rem;
  padding: 0.75rem;
  background-color: color(midBlue);
  color: color(white);
  border: none;
  border-radius: 8px;
  @include textStyle(16px, 500);
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    background-color: darken(color(midBlue), 10%);
  }

  &:disabled {
    background-color: color(midBlue);
    cursor: not-allowed;
  }
}

.errorInput {
  border-color: color(red) !important;
}

.errorMessage {
  color: color(red);
  @include textStyle(14px, 400);
  margin: 0.25rem 0 0;
}
