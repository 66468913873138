@import '../../../styles/scss/abstracts/_variables.module.scss';

.integrations {
  background-color: color(magnolia);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 24px;
  width: 100%;

  @media (min-width: 1200px) {
    max-width: 1200px;
    padding: 40px;
  }

  @media (min-width: 1440px) {
    max-width: 1440px;
    padding: 40px 120px;
  }

  @media (min-width: 1920px) {
    max-width: 1920px;
  }

  .integrationsHeader {
    width: 100%;
    margin-bottom: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      @include textStyle(32px, 500);
      color: color(black);
    }
  }
}

.addIntegrationButton {
  background-color: color(darkOrange) !important;
  padding: 8px 16px 8px 16px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  @include textStyle(14px, 500);

  .plusIcon {
    margin-left: 5px;
  }
}

.integrationsContent {
  padding: 32px;
  background-color: color(white);
  border-radius: 24px;
}

.integrationCard {
  width: 100%;
  background-color: transparent;

  .integrationCardHeader {
    margin-bottom: 24px;
    h1 {
      @include textStyle(20px, 700);
      color: color(black);
    }

    p {
      @include textStyle(14px, 600);
      color: color(carbon400);
      margin-top: 8px;
    }
  }

  .integrationCardBody {
    ul {
      box-shadow: none;
      display: flex;
      align-items: center;
      gap: 10px 45px;
      margin-bottom: 33px;
      flex-wrap: wrap;

      li {
        outline: none;
        border-bottom: 3px solid transparent;
        padding-bottom: 18px;
        &:hover {
          cursor: pointer;
          background-color: transparent;
        }

        &[aria-selected='true'] {
          border-bottom: 3px solid color(midBlue);
        }
      }
    }
    .tabContent {
      display: flex;
      align-items: center;
      div {
        width: 42px;
        height: 42px;
        padding: 11px 10px 11px 9px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: color(white);
        margin-right: 12px;
      }

      h1 {
        @include textStyle(14px, 500);
        color: color(black);
        margin-right: 3px;
      }

      span {
        @include textStyle(14px, 700);
        color: color(black);
      }
    }

    .tabPanelContent {
      .tabPanelContentHeader {
        h1 {
          @include textStyle(16px, 800);
          color: color(black);
        }
        margin-bottom: 24px;
      }

      .emptyIntegration {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;

        h1 {
          @include textStyle(16px, 800);
          color: color(black);
        }
        p {
          @include textStyle(14px, 400);
          color: color(carbon400);
        }
      }

      .tabPanelContentBody {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        max-height: 340px;
        overflow-y: scroll;
      }
    }
  }
}

.integrationBox {
  display: flex;
  width: 238px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 32px;
  background-color: color(white);
  border: 1px solid color(lightGrey7);
  position: relative;
  cursor: pointer;

  .integrationSettings {
    position: absolute;
    right: 10px;
    top: 20px;
    cursor: pointer;
    color: color(manatee);
  }

  .integrationBoxHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    .integrationBoxIcon {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .integrationBoxTitle {
      h1 {
        @include textStyle(16px, 700);
        color: color(black);
      }

      p {
        @include textStyle(12px, 400);
        color: color(grey);
      }
    }
  }

  .integrationConnectBtn {
    padding: 6px 16px 6px 16px;
    border-radius: 8px;
    background-color: color(white);
    border: 1px solid color(lightGrey7);
    cursor: pointer;
    @include textStyle(12px, 400);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &.connected {
      svg {
        color: color(shamrockGreen);
      }
    }

    &.disabled {
      cursor: not-allowed;
      background-color: color(lightGrey7);
      color: color(black);
    }
  }
}

.addIntegrationsPoupContainer {
  height: fit-content;
  .addIntegrationsPoupHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      @include textStyle(16px, 700);
      color: color(black);
    }

    .addIntegrationsPoupInputContainer {
      display: flex;
      align-items: center;
      position: relative;

      .searchIcon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: color(carbon400);
      }

      .clearIcon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: color(carbon400);
        cursor: pointer;
      }

      input {
        border: 1px solid color(lavendeGray);
        padding: 10px 16px 10px 38px;
        height: 34px;
        width: 298px;
        border-radius: 24px;
        @include textStyle(14px, 500);
      }
    }

    svg {
      color: color(manatee);
      cursor: pointer;
    }
  }

  .addIntegrationsPoupBody {
    height: 100%;
    overflow-y: auto;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.emptyIntegrationPlaceholder {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;

  h1 {
    @include textStyle(16px, 800);
    color: color(black);
  }
}
