// Import colors from TypeScript file
// import from _variables scss file
@import '../scss/abstracts/variables.module';
@import '../scss/helper';
@import '../scss/abstracts/_mixins.scss';

.header {
  // box-shadow: 0px 0px 5px 1px $global-header-color-border;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999; // Make it hight to place above all elements (doesn't seems good, should be fix)
  background: $global-header-color-white;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-right: 24px;
  padding-left: 24px;
  height: 72px;
  max-height: 72px;

  * {
    font-family: $global-header-font-family;
    font-size: 0.97em;
    line-height: 1.5em;
  }

  @media (max-width: 1400px) {
    gap: 0; // Remove space between nav items and logo
    padding-right: 20px;
    padding-left: 20px;
  }

  .logoDivider {
    width: 1.5px;
    height: 31px;
    background-color: color(gainsboro2);
    margin: 0 16px;
  }

  .logo-container {
    display: flex; // Flex display
    align-items: center; // Align items to the center
    flex-shrink: 0; // Prevent item from shrinking
    width: 93px; // Add fix side, prevent deform logo
    height: 22px;
    cursor: pointer; // Change cursor to pointer
  }

  .hamburger-menu {
    display: flex; // Flex display
    align-items: center; // Align items to the center
    justify-content: center;
    text-align: center;
    border-radius: 50px; // Rounded border (adjust as needed)
    // border: 1px solid $global-header-color-black; // Border properties (adjust color and size as needed)
    // padding: 0.5rem 1rem; // Adjust padding as needed
    outline: none; // Remove outline
    height: 2rem;
    width: 2rem;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .header-link {
    @apply flex items-center shrink-0 h-full; // px: to have space around, flex-items-center: to make it center in the box, shrink-0: prevent shrinking element in small space
    color: color(black);
    padding: 0 8px;
    margin-right: 8px;
    outline: none; // Remove the outline
    text-align: center;
    position: relative; // Relative position to wrap absolute pseudo element
    transition: all 0.2s ease-in-out; // Add a transition effect (when the cursor is hovering over the link)
    color: color(manatee);
    @include textStyle(14px, 400);

    .more {
      display: flex;
      align-items: center;
    }

    &:hover {
      color: color(black) !important; // Set the text color to black (#000)
      border-bottom: 0; // Remove extra border on hover
    }
  }

  .dropdownButtonText {
    button {
      @apply flex items-center gap-2;
      color: color(disabledText);
      @include textStyle(16px, 500); // Match with other link size

      @media (max-width: 1400px) {
        @include textStyle(14px, 500);
      }
    }

    &:hover {
      button {
        color: color(black);
      }
    }
  }

  .header-link,
  .company-name {
    &.active-link {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: color(midBlue);
        z-index: 1;
      }
      color: color(black);
    }

    &.active-link-alt {
      color: color(manatee);
    }
  }

  .company-name {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 30px;
    margin-right: 24px;
    cursor: pointer;

    svg {
      color: color(manatee);
    }

    button {
      @include textStyle(16px, 500); // Match with other link style
      color: color(black);
      @apply flex items-center gap-2; // Make some space between button label and arrow

      @media (max-width: 1400px) {
        @include textStyle(14px, 500);
      }
    }
  }

  .logo {
    margin-top: -4px;
    height: 30px;
    object-fit: contain;

    @media (max-width: 1400px) {
      height: 20px;
    }
  }

  .list {
    flex-grow: 1; // Grow the element
    flex-shrink: 0; // Prevent shrinking
    display: flex;
    align-items: center;
  }

  .customInput {
    height: max-content;
    display: flex;
    border-radius: 20px;
    background-color: color(grayBg2);
    position: relative;
    padding: 0.25rem;
    min-width: 515px;
    max-width: 600px;
    width: auto;
    min-height: 48px;
    border: 0.5px solid color(selectBorderColor);

    // media query for wider screen
    @media (min-width: 1980px) {
      width: 100%;
    }

    .dropdown-list {
      position: absolute;
      left: 0;
      right: 0;
      top: calc(48px + 8px); // Add 48px for the box plus 8px for the gap
      border-radius: 1rem; // Changed to rem unit
      background: $global-header-color-white;
      box-shadow: 0px 4px 24px 0px $global-header-color-lightestGray;
      font-weight: 500;
      color: $global-header-color-darkBlue;

      li:hover {
        background: $global-header-color-lightestGray;
        color: $global-header-color-darkBlue;
      }

      button {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 1em;
      }
    }

    .leftIcon {
      border-top-left-radius: 50%; /* Round the left end */
      border-bottom-left-radius: 50%;
      background-color: transparent; /* Background color for left icon */
      position: absolute;
      left: -3.5px;
      top: -1px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      svg {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: color(selectBorderColor);
        transition: all 0.2s ease-in-out;
      }

      img {
        height: 48px;
        width: 48px;
        max-width: max-content;
        border-radius: 50%;
        background-color: color(selectBorderColor);
        border: 1px solid color(selectBorderColor);
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        svg {
          color: color(white);
        }

        img {
          border-color: color(white);
          background-color: color(white);
        }
      }
    }
    .isWorkingDiv {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      margin-top: 2px;
      margin-left: 45px;
      padding-right: 10px;

      .stopButton {
        font-size: 16px;
        font-weight: bold;
        color: color(themeColor);
        margin-right: 170px;

        @media (max-width: 1400px) {
          font-size: 14px;
          position: relative;
          left: -20px;
          margin-right: 150px;
        }
      }
    }
    .rightDropdown {
      position: absolute;
      border-top-right-radius: 9999px; /* Round the right end */
      border-bottom-right-radius: 9999px;
      border-radius: 9999px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: fit-content;
      max-width: 160px;
      height: 32px;
      cursor: pointer;
      margin-right: 8px; // Match with the padding around the box
      background-color: color(white); /* Background color for right dropdown */
      right: 0;
      top: 8px;

      @media (max-width: 1400px) {
        width: 100%;
        button {
          width: 80px !important;
        }
      }

      .branchContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 16px;
        padding-right: 10px;

        svg {
          color: color(black);
        }

        button:nth-child(1) {
          width: 100%;
          @apply px-1; // 4px left and right
        }

        & > div:nth-child(1) {
          width: 100%;
        }
      }

      .dropdown-container {
        position: relative;
        background: $global-header-color-white;
        margin-right: 0.5em;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $global-header-color-gray;
        border: 0;
        border-radius: 9999px;
        padding: 0.5em 1em;
        padding-right: 0.5em;
        cursor: pointer;
        .dropdown-button {
          display: flex;
          gap: 0.75em;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
      }
    }
    .textAreaContainer {
      margin-right: 5px;
      display: flex;
      align-items: center;
      width: 100%;
      .textAreaField {
        width: 57%;
        margin-left: 50px;
        border: none;
        outline: none;
        background: transparent;
        color: color(manatee);
        @include textStyle(14px, 400);
        overflow-y: auto;
        min-height: 0px;
        max-height: 120px;
        position: relative;
        resize: none;
        outline: none;
        @include hideScrollbar;
        box-sizing: border-box;
      }
    }
  }

  .user {
    max-width: max-content;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  .search {
    display: flex;
    justify-content: flex-end;

    gap: 1em;
    top: 10px;
    width: 100%;
    position: relative;
  }

  .profile {
    display: flex;
    // gap: 1em;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    line-height: 0;
    a {
      min-width: 6em;
      margin: 0;
      height: 100%;
      display: flex;
    }
  }
}

@media (min-width: 800px) {
  img.user {
    object-fit: cover;
    object-position: center;
  }

  .logoAndExpand {
    display: flex;
    align-items: center;
    // width: 100%;
    height: 100%;
    // max-width: 145px;
    .logo {
      width: 100%;
      // max-width: 145px;
    }
  }

  .nav {
    // display: flex;
    flex-grow: 1;
    gap: 1rem; // Some space between nav links and AI input in smaller screen
    height: 100%; // Take up all parent height

    &.grow {
      flex-grow: 1; // Allow the item to grow within a flex container for larger screens
    }
    &.items-center {
      align-items: center; // Align items to the center for larger screens
    }
  }
}

@media (max-width: 800px) {
  // .header {
  //   flex-direction: column;
  //   .nav {
  //     width: 100%;
  //   }
  // }

  .logoAndExpand {
    // width: 100%;
    // max-width: 200px;
    display: flex;
    justify-content: space-between;
    // flex-direction: row;
    align-items: center;
  }

  img.user {
    height: 40px;
    width: 40px;
    min-width: 40px;
    object-fit: contain;
    object-position: center;
  }

  .header {
    // padding: 1em;

    // .header-link,
    // .company-name {
    //   padding: 0.5em;
    // }

    .company-name {
      border: 0;
    }

    .list {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 1em 0;
      gap: 1em;
    }

    .search {
      display: flex;
      flex-direction: column;
      gap: 1em;
      align-items: flex-start;
    }

    .profile {
      display: flex;
      gap: 1em;
      text-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      line-height: 0;
    }
  }
}

// @media (min-width: 1244px) {
//   .nav {
//     width: 85%;
//   }
// }

.errorDiv {
  width: 345px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 6px;

  svg {
    cursor: pointer;
  }

  .errorText {
    color: color(red);
    @include textStyle(14px, 400);
  }

  .retryButton {
    color: color(themeColor);
    @include textStyle(15px, bold);
    cursor: pointer;
  }
}

.userSettingsLi {
  @apply font-medium px-6 py-3 h-[48px];
  &:hover {
    color: color(black);
    background-color: color(lightGrey4);
  }
  div {
    @apply flex gap-4 h-[24px] p-0;
    p {
      @apply p-0 h-auto ml-0 text-base font-medium;
      line-height: 19.2px;
    }
  }
}

.userSettingsDisabled {
  color: color(lightGrey3);
  &:hover {
    cursor: not-allowed;
    color: color(lightGrey3);
  }

  &:hover {
    p {
      color: color(lightGrey3);
    }
  }
}

.listWrapper {
  min-width: 100px;
  margin: 4px 0 0 0;
  list-style: none;
  border: none;
  background: white;
  overflow: visible;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
  transition: all ease-in-out 3s;
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  width: 200px;
  overflow: hidden;
}

.dropdownToggleBtn {
  width: auto;
  max-width: 100px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important;
  text-align: left;
  padding-right: 5px;
}
