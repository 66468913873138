@import '../../../../../styles/scss/abstracts/variables.module';

.uploadFileStage {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5;

  .uploadFileStageTitle {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      @include textStyle(24px, 600);
      color: color(black);
      line-height: 2;
    }

    .descriptionContainer {
      display: flex;
      justify-content: center;

      svg {
        color: color(midBlue);
        margin-top: 2px;
        margin-right: 5px;
      }

      p {
        width: 65%;
        @include textStyle(16px, 400);
        color: lighten(color(black), 30%);
        text-align: center;
        display: flex;
      }
    }
  }

  .uploadFileContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .successContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    z-index: 101;
    position: relative;

    h2 {
      @include textStyle(24px, 600);
      color: color(black);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      margin-top: -20px;

      svg {
        color: color(darkGreen);
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      box-shadow: none;
      border: none;
      border-radius: 0;

      li {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.5rem;

        span {
          max-width: 400px;
          @include textStyle(16px, 500);
          margin-top: -3px;
        }

        &:hover {
          background: transparent;
          color: color(black);
        }

        svg {
          color: color(midBlue);
        }
      }
    }

    .buttonContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100%;
    }

    .logoutButton {
      margin-top: 0px;
      padding: 0.75rem;
      background-color: transparent;
      color: color(black);
      border: none;
      border-radius: 8px;
      @include textStyle(16px, 500);
      cursor: pointer;
      transition: background-color 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: color(borderGrey3);
        color: color(black);
      }
    }

    .generatePlanButton {
      margin-top: 0px;
      width: 100%;
      padding: 0.75rem;
      background-color: color(midBlue);
      color: color(white);
      border: none;
      border-radius: 8px;
      @include textStyle(16px, 500);
      cursor: pointer;
      transition: background-color 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      z-index: 101;

      &:hover {
        background-color: darken(color(midBlue), 10%);
      }

      &:disabled {
        background-color: color(midBlue);
        cursor: not-allowed;
      }
    }
  }
}
