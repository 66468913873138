@import '../../../../styles/scss/abstracts/variables.module';

.addCompanyButton {
  background-color: color(midBlue);
  border: none;
  cursor: pointer;
  height: 38px;
  margin-right: 10px;
  border-radius: 6px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px !important;
  transition: all 0.5s ease-in-out;
  width: fit-content;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    width: fit-content;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

    span {
      opacity: 1;
      max-width: 205px;
      margin-left: 5px;
    }
  }

  span {
    @include textStyle(16px, 400);
    color: color(white) !important;
    opacity: 0;
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  svg {
    color: color(white) !important;
    margin-right: -5px !important;
  }
}
