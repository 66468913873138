@import '../../../../../styles/scss/abstracts/variables.module';

.newCompanySetupStage {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  gap: 10px;

  .newCompanySetupStageContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    gap: 15px;
    margin-top: -30px;
  }
}

.sectionTitle {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;

  h1 {
    @include textStyle(24px, 600);
    color: color(black);

    span {
      background: linear-gradient(
        90deg,
        color(midBlue) 0%,
        color(livelyPink) 50%,
        color(vividOrange) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      font-weight: 700;
    }
  }
}

.companyPlans {
  display: flex;
  gap: 20px;
  height: 100%;
  min-height: 390px;
  max-height: 410px;

  .companyPlan {
    width: 300px;
    height: 100%;
    padding: 15px;
    border-radius: 10px;
    border: 0.5px solid color(manatee);
    border-top: 3px solid color(midBlue);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    background-color: white;
    // position: relative;
    z-index: 101;

    .bottomBadgeContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 102; // Added z-index higher than parent

      // ! added margin also for 'recommended' badge, this can't be overflowed the parent container
      margin-bottom: 30px;

      .bottomBadge {
        background-color: color(black);
        color: color(white);
        border-radius: 3px;
        @include textStyle(14px, 500);
        padding: 4px;
      }
    }

    svg {
      color: color(midBlue);
    }

    h1 {
      @include textStyle(18px, 600);
      color: color(midBlue);
      transition: all 0.3s ease;
      line-height: 1.2;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      gap: 10px;

      span {
        @include textStyle(14px, 400);
      }
    }

    ul {
      box-shadow: none;
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 5px;

      li {
        @include textStyle(16px, 400);
        color: color(black);
        transition: all 0.3s ease;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: flex-start;
        gap: 8px;

        svg {
          min-width: 16px;
          height: 16px;
          margin-top: 3px;
          color: color(black);
        }

        span {
          flex: 1;
        }

        &:hover {
          background: transparent;
        }
      }
    }

    &:hover {
      @apply shadow-md;
      transform: scale(1.05);
      border-color: color(midBlue);
    }

    &.selected {
      background-color: color(midBlue);
      border-color: color(midBlue);
      @apply shadow-md;

      &:hover {
        transform: none;
      }

      h1 {
        color: color(white);
      }

      ul {
        li {
          color: color(white);
        }
      }

      svg {
        color: color(white);
      }
    }
  }
}

.breadcrumbs {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 8px;
  @include textStyle(16px, 400);
  color: color(manatee);
  width: 100%;
  padding-left: 25px;
  margin-bottom: 15px;

  .current {
    @include textStyle(16px, 500);
    color: color(black);
  }
}
